<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card style="min-height:168px; height: 100%;">
      <v-card-title style="word-break: break-word; width: 97%;">
        {{ $tc('label.intervalo_meta', 1) }} {{ subtitulo }}
      </v-card-title>
      <v-card-text>
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col cols="12" >
              <v-data-table
                :headers="headers"
                :items="metas"
                hide-default-footer
                disable-pagination
                :key="tableKey"
                :items-per-page="-1"
                :no-data-text="$t('message.nenhum_intervalo')"
                style="overflow:hidden">
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-center">
                      <span v-text="formatValue(item.vlrMetaDe)"></span>
                    </td>
                    <td class="text-center">
                      <span v-if="item.vlrMetaAte" v-text="formatValue(item.vlrMetaAte)"></span>
                    </td>
                    <td class="text-center">
                      <span v-text="formatarVerba(item.vlrBonificacao)"></span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="close">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { getMoney, getPercent } from '../../../common/functions/helpers';

export default {
  data() {
    return {
      resource: this.$api.apuracaoAcaoListagem(this.$resource),
      dialog: false,
      tableKey: 1,
      headers: [
        {
          text: this.$t('label.meta_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'vlr_bonificacao', sortable: false, align: 'center',
        },
      ],
      metas: [],
      calculoApuracao: null,
      formaBonificacao: null,
    };
  },
  props: {
    subtitulo: {
      type: String,
      default: '',
    },
  },
  methods: {
    getPercent,
    getMoney,
    open(idAcao, calculoApuracao, formaBonificacao) {
      this.calculoApuracao = calculoApuracao;
      this.formaBonificacao = formaBonificacao;
      this.buscarMetas(idAcao);
    },
    close() {
      this.dialog = false;
    },
    buscarMetas(idAcao) {
      this.resource.buscarMetas({ idAcao }).then((res) => {
        this.metas = res.data;
        this.dialog = true;
      });
    },
    formatValue(value) {
      if (this.calculoApuracao.includes('VALOR')) {
        return getMoney(value);
      }
      if (this.calculoApuracao.includes('PERCENTUAL')) {
        return getPercent(value);
      }
      return value;
    },
    formatarVerba(verba) {
      return this.formaBonificacao === 'PERCENTUAL' ? getPercent(verba) : getMoney(verba);
    },
  },
};
</script>
