<template>
  <v-dialog v-model="exibeModal"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition">
    <v-card class="DashboardModalPendencias--background-color">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="confirmacaoFechamentoModal">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title><b>{{ $tc('title.aprovacao_massiva_nds', 1).toUpperCase()}}</b></v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row class="start pb-4 pt-12">
          <v-col cols="5" sm="5" class="mx-3">
            <v-text-field
              v-model="searchQuery"
              append-icon="search"
              :label="$t('label.pesquisa_modal_aprovacao_massiva')"
              :disabled="loading"
              single-line
              clearable
              hide-details>
            </v-text-field>
          </v-col>
          <v-row  align="center" class="pl-5">
            <v-col cols="auto">
              <v-btn density="compact" @click="aprovar">{{ $tc('label.aprovar', 1)}}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn density="comfortable" @click="reprovar">{{ $tc('label.reprovar', 1)}}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn density="default" @click="solicitarAnalise">{{ $tc('label.solicitar_analise', 1)}}</v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row justify="space-between" v-show="!esperar" class="pb-2">
          <v-autocomplete
            id="combo-categorias"
            class="mx-3 px-3"
            v-model="categoria"
            :items="itemsCategoria"
            :clearable="true"
            autocomplete="off"
            return-object
            item-text="codNomeCategoria"
            item-value="id"
            :label="`${$tc('label.categoria', 1)}`"
            :menu-props="{ offsetOverflow: false }"
            style="max-width: 30em">
          </v-autocomplete>
          <v-menu
            ref="data_contabilizacao_todos"
            v-if="listaAcoesApuracoes.length && showDataContabilizacaoAll"
            :close-on-content-click="false"
            :nudge-right="40"
            v-model="menuAteraDataSelecionados"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on: menu }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-on="{ ...tooltip, ...menu }"
                    class="mr-3 mb-3"
                    id="btn_salvar"
                    color="primary"
                    :disabled="!selected.length">
                    <v-icon class="mr-2">event</v-icon>
                    {{ $t('label.definir_data') }}
                  </v-btn>
                </template>
                <span>{{ $t('message.definir_todas_data_contabilizacao') }}</span>
              </v-tooltip>
            </template>
            <v-date-picker
              locale="pt-br"
              color="primary"
              scrollable
              v-model="dataContabilizacaoAll">
              <v-spacer></v-spacer>
              <v-btn text color="primary"
                @click.native="menuAteraDataSelecionados = false">
                  {{ $t('label.cancelar') }}
              </v-btn>
              <v-btn text color="primary"
                @click.native="aplicarDataContabilizacaoAll">
                {{ $t('label.aplicar') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="listaAcoesApuracoes"
          v-model="selected"
          :loading="loading"
          :show-select="!somenteLeitura"
          @toggle-select-all="realizarSelecaoTotal"
          :options.sync="pagination"
          :server-items-length="totalPage"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30, 40, 50],
          }">
          <template v-slot:[`item.nomeTipoUso`]="{ item }">
            <v-btn icon v-if="mostrarModalRateio(item)"  class="mx-0" @click.native="visualizarRateioContabil(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
            <v-autocomplete
                v-else
              class="py-0"
              v-model="item.idTipoUso"
              :items="listaTiposUso"
              :disabled="item.desabilitaTipoUso"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :clearable="true"
              @change="valorTipoUsoAlterado(item)"
            ></v-autocomplete>
          </template>
          <template v-slot:[`item.detalhar`]="{ item }">
            <v-btn
              color="primary"
              @click="detalhar(item)"
              class="text-capitalize white--text"
              small
            >
              {{ $tc('label.ver_detalhes' )}}
            </v-btn>
          </template>
          <template v-slot:[`item.numNotaDebito`]="{ item }">
            <v-btn v-if="exibirBotaoExibicaoMultiplasNds(item)" icon class="mx-0" @click.stop="abrirModalExibicaoMultiplasNds(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
            <span v-else-if="item.countNotasDebito <= countNotasExibicao">{{ item.numNotaDebito }}</span>
            <v-btn icon v-else class="mx-0" @click.native="visualizarNotasDebito(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.valorRecebimento`]="{ item }">
            <span>{{ getMoney(item.valorRecebimento) }}</span>
        </template>
        <template v-slot:[`item.extensoes`]="{ item }">
          <div v-if="item.extensoes">
            <span v-if="!item.extensoes.includes('/')">{{ item.extensoes }}</span>
            <v-btn icon v-else class="mx-0" @click.native="visualizarCategorias(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.dataContabilizacao="{ item }" class="Botoes_Acao" nowrap :class="['text-sm-left']" @click.stop>
          <v-menu
            v-if="item.informaDataContabilizacao"
            :key="item.id"
            v-model="item.menu"
            ref="data_contabilizacao"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                :disabled="item.desabilitaDataContabilizacao"
                class="CalendarioForm-data__text"
                readonly
                clearable
                v-model="item.dataFormatada"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              color="primary"
              scrollable
              @input="setaDataFormatada(item)"
              v-model="item.dataContabilizacao"></v-date-picker>
          </v-menu>
        </template>
        <template v-slot:item.modalidade="{ item }">
            <span v-if="item.modalidade">{{ $t(`configuracao.CALCULO_BONIFICACAO.${item.modalidade}`) }}</span>
            <span v-else>{{ $t('label.nao_aplica') }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <planejamento-fluxo-modal-pendencias
      ref="planejamentoFluxoModalPendencias"
      :message="$t('message.fechar_sem_salvar')"
      :titulo-modal="tituloModal"
      :persistent="true"
      maxWidth="600"
      @aprovar="executarAprovadas"
      @reprovar="executarReprovadas"
      @executar_analise="executarParaAnalise"
      @agree="close">
    </planejamento-fluxo-modal-pendencias>

    <apuracao-acao-lista-modal-gerar-nd
      ref="modalGerarNd"
      v-if="acaoModal"
      v-model="acaoModal"
      :abrir-modal="abrirModalND"
      :ocultaResumo="true"
      @ApuracaoAcaoListaModalGerarNd__fechar="fecharModalND">
    </apuracao-acao-lista-modal-gerar-nd>

    <modal-nota-debito
      ref="modalNotaDebito">
    </modal-nota-debito>

    <modal-exibicao-multiplas-nds
      ref="ModalExibicaoMultiplasNds">
    </modal-exibicao-multiplas-nds>

    <modal-rateio-contabil
      ref="modalRateioContabil">
    </modal-rateio-contabil>

    <modal-lista-valores
      ref="modalListaValores">
    </modal-lista-valores>

    <v-dialog v-model="dialogJustificativaTipoUso" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('label.informe_justificativa') }}</span>
        </v-card-title>
        <v-form lazy-validation ref="form">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="justificativa_textarea"
                  required
                  :rows="1"
                  auto-grow
                  autofocus
                  maxlength="150"
                  v-model.trim="justificativaTipoUso"
                  :rules="[rules.required]">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            color="secundary"
            @click="modalJustificativaCancelar">{{ $t('label.cancelar') }}</v-btn>
          <v-btn text
            color="primary"
            @click="modalJustificativaConfirmar">{{ $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ModalListaValores from '@/produto/spa/apuracao-acao-lista/ModalListaValores';
import ApuracaoAcaoListaModalGerarNd from '@/produto/spa/apuracao-acao-lista/ApuracaoAcaoListaModalGerarNd';
import ModalNotaDebito from '@/produto/spa/apuracao-acao-lista/ModalNotaDebito';
import ModalRateioContabil from '@/produto/spa/apuracao-acao-lista/ModalRateioContabil';
import { skipLoading } from '../../../common/functions/loading';
import { pendenciasAcaoApuracaoPaginada } from '../../../common/resources/workflow-acao';
import { getMoney } from '../../../common/functions/helpers';
import download from '../../../common/resources/downloads';
import getBasePath from '../../../common/functions/api-resource';
import PlanejamentoFluxoModalPendencias from '../../../shared-components/PlanejamentoFluxoModalPendencias';
import ModalExibicaoMultiplasNds from '../../../shared-components/ModalExibicaoMultiplasNdsCamelCase';

export default {
  name: 'DashboardModalPendencias',
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      exibeModal: false,
      buscaInicial: false,
      dialogJustificativaTipoUso: false,
      selected: [],
      listaAcoesApuracoes: [],
      listaAcoesSelecionadas: [],
      listaApuracoesSelecionadas: [],
      searchQuery: null,
      somenteLeitura: false,
      pendenciaExecutada: false,
      acaoModal: null,
      abrirModalND: false,
      countNotasExibicao: 2,
      searchQueryArray: [],
      loading: false,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      lastPagination: {},
      escondePaginacao: true,
      itemsCategoria: [],
      categoria: {},
      totalPage: 0,
      timeout: 0,
      esperar: false,
      tipoUso: null,
      listaTiposUso: [],
      tamanhoTotalProdutos: 0,
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
      },
      headers: [
        {
          text: this.$tc('label.cod_acao', 1), value: 'idAcao', sortable: true, class: 'nowrap',
        },
        {
          text: this.$tc('label.numero_da_nd', 1), value: 'numNotaDebito', sortable: true, width: '8%',
        },
        {
          text: this.$tc('label.periodo_vigencia', 1), value: 'periodo', sortable: true,
        },
        {
          text: this.$tc('label.criador_campanha', 1), value: 'usuarioCriador', sortable: true,
        },
        {
          text: this.$tc('label.categoria', 1), value: 'extensoes', sortable: true, class: 'nowrap',
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'fornecedor', sortable: true, class: 'nowrap',
        },
        {
          text: this.$tc('label.tipo_uso', 1), value: 'nomeTipoUso', sortable: true, width: '8%',
        },
        {
          text: this.$tc('label.data_contabilizacao', 1), value: 'dataContabilizacao', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.modalidade', 1), value: 'modalidade', sortable: true, class: 'nowrap',
        },
        { text: this.$tc('label.vlr_total_recebimento', 1), value: 'valorRecebimento', sortable: true },
        { text: this.$tc('label.prazo_validade', 1), value: 'prazoPagamento', sortable: true },
        { text: this.$tc('label.tipo_pagamento', 1), value: 'descTipoBeneficio', sortable: true },
        {
          text: '', value: 'detalhar', sortable: false, width: '10%',
        },
      ],
      tituloModal: '',
      justificativaTipoUso: '',
      acaoApuracaoSelecionada: {},
      menuAteraDataSelecionados: false,
      dataContabilizacaoAll: new Date().toISOString().substr(0, 10),
      showDataContabilizacaoAll: false,
      datasPreenchidas: true,
      workspace: {},
    };
  },
  props: {
    value: Object,
    abrirModalPendencia: false,
  },
  components: {
    PlanejamentoFluxoModalPendencias,
    ApuracaoAcaoListaModalGerarNd,
    ModalNotaDebito,
    ModalListaValores,
    ModalRateioContabil,
    ModalExibicaoMultiplasNds,
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    isUsuarioControleComercial() {
      return this.usuarioLogado.perfil.toLowerCase() === 'controle comercial';
    },
  },
  watch: {
    abrirModalPendencia() {
      this.dialog = this.abrirModalPendencia;
    },
    searchQuery() {
      if (this.exibeModal) {
        this.buscaInicial = false;
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.filtrar(true);
        }, 500);
      }
    },
    selected(listaSelecionada) {
      this.listaAcoesApuracoes.forEach((dados) => {
        dados.desabilitaTipoUso = true;
        dados.desabilitaDataContabilizacao = true;
      });
      listaSelecionada.forEach((dados) => {
        dados.desabilitaTipoUso = !dados.informaDataContabilizacao;
        dados.desabilitaDataContabilizacao = !dados.informaDataContabilizacao;
      });
    },
    categoria: {
      handler() {
        this.buscaInicial = false;
        this.filtrar(true);
      },
      deep: true,
    },
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)
          && ((!this.pagination.sortBy && !this.lastPagination.sortBy)
          || (this.pagination.sortBy && this.lastPagination.sortBy
            && this.pagination.sortBy[0] === this.lastPagination.sortBy[0]
            && this.pagination.sortDesc[0] === this.lastPagination.sortDesc[0]))) {
          return;
        }
        this.lastPagination = this.pagination;
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    open() {
      this.buscaInicial = true;
      this.carregarWorkspaceFiltros();
      Promise.all([
        this.buscarTiposUso(),
        this.carregarFiltros(),
      ]).then(() => {
        this.exibeModal = true;
      });
    },
    close() {
      this.exibeModal = false;
      this.resetaModal();
      if (this.pendenciaExecutada) {
        this.$emit('DashboardModalPendencias__fechar', true);
      } else {
        this.$emit('DashboardModalPendencias__fechar', false);
      }
    },
    resetaModal() {
      this.selected = [];
      this.listaAcoesApuracoes = [];
      this.listaAcoesSelecionadas = [];
      this.listaApuracoesSelecionadas = [];
      this.iniciarTodosSelecionados = false;
      this.searchQuery = null;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 20;
    },
    filtrar(zeraPaginacao = false) {
      if (!this.buscaInicial) {
        setTimeout(() => {
          this.buscaPendencias(zeraPaginacao);
        }, 1000);
      }
    },
    mostrarModalRateio(item) {
      return this.isAmbienteKabum && item.modalidade === 'VALOR_FIXO' && item.indCadastroRateioContabil;
    },
    buscaPendencias(zeraPaginacao = false) {
      if (zeraPaginacao) {
        this.pagination.page = 1;
        this.selected = [];
      }
      const params = {
        asc: this.pagination.sortDesc ? !this.pagination.sortDesc[0] : true,
        colunaOrdenacao: this.pagination.sortBy ? this.pagination.sortBy[0] : 'dta_solicitacao',
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };
      const filtro = {};
      if (this.searchQuery && this.searchQuery.length) {
        if (this.searchQuery.includes(',')) {
          const searchQuery = this.searchQuery.split(',');
          searchQuery.forEach((valor) => {
            this.searchQueryArray.push(`${valor}%`);
          });
          params.filtroArray = this.searchQueryArray;
        } else {
          params.filtro = this.searchQuery;
        }
        filtro.searchQuery = this.searchQuery;
      }
      this.salvarFiltrosWorkspace(filtro);

      params.id_perfil = this.usuarioLogado.idPerfil;
      if (this.categoria && this.categoria.id) {
        params.id_categoria = this.categoria.id;
      }
      this.organizarColunasOrdenacao(params);

      this.listaAcoesApuracoes = [];
      this.loading = true;
      pendenciasAcaoApuracaoPaginada(params, this.$resource)
        .then((response) => {
          this.buscaInicial = false;
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.listaAcoesApuracoes = [...response.data.resposta];
          const acaoDataContabilizacao = this.listaAcoesApuracoes.filter((dados) => dados.informaDataContabilizacao === true);
          if (acaoDataContabilizacao.length > 0) {
            this.showDataContabilizacaoAll = true;
          }
          this.searchQueryArray = [];
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
          this.loading = false;
        })
        .catch((err) => {
          if (err.data) {
            this.$toast(err.data.error);
          }
        });
    },
    carregarWorkspaceFiltros() {
      const entidade = 'aprovacao_massiva_nd';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          if (Object.keys(this.workspace).length) {
            this.searchQuery = this.workspace.filtros.searchQuery;
          }
          this.buscaPendencias(false);
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace(filtro) {
      this.workspace.filtros = filtro;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'aprovacao_massiva_nd';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'aprovacao_massiva_nd';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    confirmacaoFechamentoModal() {
      this.close();
    },
    buscarTiposUso() {
      return this.workflowAcaoMagaluResource
        .buscarTiposUso()
        .then((retorno) => {
          this.listaTiposUso = retorno.data;
        })
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    aprovar() {
      this.datasPreenchidas = !this.selected.some((dados) => dados.informaDataContabilizacao
        && (!dados.dataFormatada || dados.dataFormatada === null));
      if (this.selected.length > 0 && this.datasPreenchidas) {
        this.tituloModal = this.$tc('label.aprovar', 1);
        this.$refs.planejamentoFluxoModalPendencias.open();
      } else if (this.selected.length > 0 && !this.datasPreenchidas) {
        this.datasPreenchidas = true;
        this.$toast(this.$tc('message.existe_acao_apuracao_sem_data_contabilizacao'));
      } else {
        this.$toast(this.$tc('message.nao_existem_selecao_para_aprovar'));
      }
    },
    reprovar() {
      if (this.selected.length > 0) {
        this.tituloModal = this.$tc('label.reprovar', 1);
        this.$refs.planejamentoFluxoModalPendencias.open();
      } else {
        this.$toast(this.$tc('message.nao_existem_selecao_para_reprovar'));
      }
    },
    solicitarAnalise() {
      if (this.selected.length > 0) {
        this.tituloModal = this.$tc('label.solicitar_analise', 1);
        this.$refs.planejamentoFluxoModalPendencias.open();
      } else {
        this.$toast(this.$tc('message.nao_existem_selecao_para_solicitar_analise'));
      }
    },
    executarAprovadas(param) {
      this.listaAcoesSelecionadas = this.selected.filter((listaDadosSelecionados) => listaDadosSelecionados.isAcao === true);
      this.listaApuracoesSelecionadas = this.selected.filter((listaDadosSelecionados) => listaDadosSelecionados.isApuracao === true);

      if (this.listaAcoesSelecionadas.length && !this.listaApuracoesSelecionadas.length) {
        this.aprovarAcoes(param).then(() => {
          this.$toast(this.$t('message.acoes_aprovadas'));
          this.resetaModal();
          this.filtrar();
        });
      }
      if (this.listaApuracoesSelecionadas.length && !this.listaAcoesSelecionadas.length) {
        this.aprovarApuracoes(param).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.apuracoes_aprovadas'));
        });
      }
      if (this.listaAcoesSelecionadas.length && this.listaApuracoesSelecionadas.length) {
        Promise.all([
          this.aprovarAcoes(param),
          this.aprovarApuracoes(param),
        ]).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.acao_apuracao_aprovadas'));
        });
      }
      this.pendenciaExecutada = true;
    },
    executarReprovadas(param) {
      this.listaAcoesSelecionadas = this.selected.filter((listaDadosSelecionados) => listaDadosSelecionados.isAcao === true);
      this.listaApuracoesSelecionadas = this.selected.filter((listaDadosSelecionados) => listaDadosSelecionados.isApuracao === true);

      if (this.listaAcoesSelecionadas.length && !this.listaApuracoesSelecionadas.length) {
        this.reprovarAcoes(param).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.acoes_reprovadas'));
        });
      }
      if (this.listaApuracoesSelecionadas.length && !this.listaAcoesSelecionadas.length) {
        this.reprovarApuracoes(param).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.apuracoes_reprovadas'));
        });
      }
      if (this.listaAcoesSelecionadas.length && this.listaApuracoesSelecionadas.length) {
        Promise.all([
          this.reprovarAcoes(param),
          this.reprovarApuracoes(param),
        ]).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.acoes_apuracoes_reprovadas'));
        });
      }
      this.pendenciaExecutada = true;
    },
    executarParaAnalise(param) {
      this.listaAcoesSelecionadas = this.selected.filter((listaDadosSelecionados) => listaDadosSelecionados.isAcao === true);
      this.listaApuracoesSelecionadas = this.selected.filter((listaDadosSelecionados) => listaDadosSelecionados.isApuracao === true);

      if (this.listaAcoesSelecionadas.length && !this.listaApuracoesSelecionadas.length) {
        this.enviarAcoesParaAnalise(param).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.acoes_enviadas_para_analise'));
        });
      }
      if (this.listaApuracoesSelecionadas.length && !this.listaAcoesSelecionadas.length) {
        this.enviarApuracoesParaAnalise(param).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.apuracoes_enviadas_para_analise'));
        });
      }
      if (this.listaAcoesSelecionadas.length && this.listaApuracoesSelecionadas.length) {
        Promise.all([
          this.enviarAcoesParaAnalise(param),
          this.enviarApuracoesParaAnalise(param),
        ]).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.acoes_apuracoes_enviadas_para_analise'));
        });
      }
      this.pendenciaExecutada = true;
    },
    aprovarAcoes(param) {
      const promises = [];
      this.listaAcoesSelecionadas.forEach((acao) => {
        const { idAcao } = acao;
        const params = {
          idAcao,
          observacao: param.justificativa,
          dataContabilizacao: acao.dataFormatada ? acao.dataFormatada : '',
        };
        if (acao.idTipoUsoAcaoTipo === acao.idTipoUso) {
          params.idTipoUso = 0;
          params.justificativaTipoUso = ' ';
        } else {
          params.idTipoUso = acao.idTipoUso;
          params.justificativaTipoUso = acao.justificativaTipoUso;
        }
        if (params.observacao.length === 0) {
          params.observacao = ' ';
        }
        const promise = this.workflowAcaoResource.aprovacaoMassiva(params, params)
          .then(() => {
          })
          .catch((err) => {
            this.$error(this, err);
          });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    aprovarApuracoes(param) {
      const promises = [];
      this.listaApuracoesSelecionadas.forEach((apuracao) => {
        const { idApuracao } = apuracao;
        const params = {
          idApuracao,
          idAcao: apuracao.idAcao,
          observacao: param.justificativa,
          dataContabilizacao: apuracao.dataFormatada ? apuracao.dataFormatada : '',
        };
        if (apuracao.idTipoUsoAcaoTipo === apuracao.idTipoUso) {
          params.idTipoUso = 0;
          params.justificativaTipoUso = ' ';
        } else {
          params.idTipoUso = apuracao.idTipoUso;
          params.justificativaTipoUso = apuracao.justificativaTipoUso;
        }
        if (params.observacao.length === 0) {
          params.observacao = ' ';
        }
        const promise = this.workflowApuracaoAcaoResource.aprovacaoMassiva(params, params)
          .then(() => {
          })
          .catch((err) => {
            this.$error(this, err);
          });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    reprovarAcoes(param) {
      const promises = [];
      this.listaAcoesSelecionadas.forEach((acao) => {
        const { idAcao } = acao;
        const params = {
          idAcao,
          observacao: param.justificativa,
        };
        if (acao.idTipoUsoAcaoTipo === acao.idTipoUso) {
          params.idTipoUso = 0;
          params.justificativaTipoUso = ' ';
        } else {
          params.idTipoUso = acao.idTipoUso;
          params.justificativaTipoUso = acao.justificativaTipoUso;
        }
        const promise = this.workflowAcaoResource.reprovacaoMassiva(params, params)
          .then(() => {
          })
          .catch((err) => {
            this.$error(this, err);
          });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    reprovarApuracoes(param) {
      const promises = [];
      this.listaApuracoesSelecionadas.forEach((apuracao) => {
        const { idApuracao } = apuracao;
        const params = {
          idApuracao,
          idAcao: apuracao.idAcao,
          observacao: param.justificativa,
        };
        if (apuracao.idTipoUsoAcaoTipo === apuracao.idTipoUso) {
          params.idTipoUso = 0;
          params.justificativaTipoUso = ' ';
        } else {
          params.idTipoUso = apuracao.idTipoUso;
          params.justificativaTipoUso = apuracao.justificativaTipoUso;
        }
        const promise = this.workflowApuracaoAcaoResource.reprovacaoMassiva(params, params)
          .then(() => {
          })
          .catch((err) => {
            this.$error(this, err);
          });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    enviarAcoesParaAnalise(param) {
      const promises = [];
      this.listaAcoesSelecionadas.forEach((acao) => {
        const { idAcao } = acao;
        const params = {
          idAcao,
          observacao: param.justificativa,
        };
        if (acao.idTipoUsoAcaoTipo === acao.idTipoUso) {
          params.idTipoUso = 0;
          params.justificativaTipoUso = ' ';
        } else {
          params.idTipoUso = acao.idTipoUso;
          params.justificativaTipoUso = acao.justificativaTipoUso;
        }
        const promise = this.workflowAcaoResource.enviarAnaliseDadosMassivos(params, params)
          .then(() => {
          })
          .catch((err) => {
            this.$error(this, err);
          });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    enviarApuracoesParaAnalise(param) {
      const promises = [];
      this.listaApuracoesSelecionadas.forEach((apuracao) => {
        const { idApuracao } = apuracao;
        const params = {
          idApuracao,
          idAcao: apuracao.idAcao,
          observacao: param.justificativa,
        };
        if (apuracao.idTipoUsoAcaoTipo === apuracao.idTipoUso) {
          params.idTipoUso = 0;
          params.justificativaTipoUso = ' ';
        } else {
          params.idTipoUso = apuracao.idTipoUso;
          params.justificativaTipoUso = apuracao.justificativaTipoUso;
        }
        const promise = this.workflowApuracaoAcaoResource.enviarAnaliseDadosMassivos(params, params)
          .then(() => {
          })
          .catch((err) => {
            this.$error(this, err);
          });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    detalhar(acao) {
      if (acao.isApuracao) {
        this.abrirModalGerarNd(acao);
      } else {
        this.detalharAcao(acao);
      }
    },
    abrirModalGerarNd(acao) {
      this.acaoModal = acao;
      this.acaoModal.cod_acao = acao.idAcao;
      setTimeout(() => { this.abrirModalND = true; }, 2E2);
    },
    fecharModalND() {
      this.abrirModalND = false;
      setTimeout(() => { this.acaoModal = null; }, 2E2);
    },
    detalharAcao(acao) {
      let routeName = 'detalharAcao';
      if (this.isTemplateVerbaVariavel(acao)) {
        routeName = 'detalharAcaoVerbaVariavel';
      }
      const route = this.$router.resolve({ name: routeName, params: { idAcao: acao.id, from: 'dashboard-inicial', modalDashboard: 'PENDENCIAS_NDS' } });
      this.abreNovaAbaPlanejamento(acao.id, route);
    },
    abreNovaAbaPlanejamento(idAcao, route) {
      const newTab = window.open(route.href, '_blank');
      if (newTab) {
        localStorage.setItem('idAcao', idAcao);
      }
    },
    isTemplateVerbaVariavel(acao) {
      const { template } = acao;
      return template === 'VERBA_VARIAVEL';
    },
    visualizarNotasDebito(item) {
      setTimeout(() => this.$refs.modalNotaDebito.open(item), 2E2);
    },
    visualizarRateioContabil(item) {
      setTimeout(() => this.$refs.modalRateioContabil.open(item), 2E2);
    },
    valorTipoUsoAlterado(item) {
      this.openModalJustificativa(item);
    },
    openModalJustificativa(item) {
      item.justificativaTipoUso = this.justificativaTipoUso;
      this.acaoApuracaoSelecionada = item;
      this.dialogJustificativaTipoUso = true;
    },
    closeModalJustificativa() {
      this.dialogJustificativaTipoUso = false;
    },
    modalJustificativaConfirmar() {
      this.acaoApuracaoSelecionada.justificativaTipoUso = this.justificativaTipoUso;
      this.acaoApuracaoSelecionada = {};
      this.justificativaTipoUso = '';
      this.closeModalJustificativa();
    },
    modalJustificativaCancelar() {
      this.acaoApuracaoSelecionada.justificativaTipoUso = '';
      this.acaoApuracaoSelecionada.idTipoUso = this.acaoApuracaoSelecionada.idTipoUsoAcaoTipo;
      this.acaoApuracaoSelecionada = {};
      this.justificativaTipoUso = '';
      this.closeModalJustificativa();
    },
    realizarSelecaoTotal(toggleSelectAll) {
      if (toggleSelectAll.value) {
        this.selected = [];
        this.selected = [...this.listaAcoesApuracoes];
      } else {
        this.selected = [];
      }
    },
    visualizarCategorias(item) {
      const listaValor = {
        titulo: this.$tc('title.categorias', 2),
        valor: item.extensoes,
      };
      setTimeout(() => this.$refs.modalListaValores.open(listaValor), 2E2);
    },
    setaDataFormatada(item, dataContabilizacaoParam = null) {
      if (!dataContabilizacaoParam) {
        item.dataFormatada = this.retornaDataFormatada(item.dataContabilizacao);
      } else {
        item.dataFormatada = this.retornaDataFormatada(dataContabilizacaoParam);
      }
      item.menu = false;
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    aplicarDataContabilizacaoAll() {
      this.selected.forEach((dados) => {
        if (dados.informaDataContabilizacao) {
          dados.dataContabilizacao = this.dataContabilizacaoAll;
          this.setaDataFormatada(dados, this.dataContabilizacaoAll);
        }
      });
      this.menuAteraDataSelecionados = false;
    },
    possuiNota(acao) {
      return !this.acaoReprovado(acao) && !this.acaoCancelada(acao)
        && (acao.possui_recebimento || acao.possuiApuracaoEmAndamento || acao.possuiNotaDebitoPreviaApuracao);
    },
    possuiNDPrevia(acao) {
      return acao.possuiApuracaoEmAndamento || acao.possuiNotaDebitoPreviaApuracao;
    },
    imprimirNDPrevia(item) {
      const { idAcao } = item;
      const numNotaDebito = item.numNotaDebito.split(',').map((num) => num.trim());
      const idApuracao = parseInt(item.idApuracao, 10);
      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = download(this.$http);
      if (item.possuiApuracaoEmAndamento) {
        resource.downloadGet(basePath, {
          param: '',
          url: `nota-debito-apuracao-em-andamento-aprovacao-massiva/preview/${idAcao}/${idApuracao}/${numNotaDebito}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
      } else {
        this.$toast(this.$t('message.calculo_nao_gerado'));
        resource.downloadGet(basePath, {
          param: '',
          url: `nota-debito/preview/${idAcao}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
      }
    },
    imprimirNota(item) {
      const idRecebimento = item.id_recebimento,
        numeroND = item.num_nota_debito;
      const idAcao = item.id;
      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = download(this.$http);
      if (!idRecebimento) {
        this.imprimirNDPrevia(item);
        return;
      }

      resource.downloadGet(basePath, {
        param: `?numNota=${numeroND}`,
        url: `nota-debito-final/${idAcao}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    buscarCategorias(autocomplete = null) {
      if (this.itemsCategoria.length === 0) {
        return this.planejamentoAcaoResource.buscarCategoria(autocomplete)
          .then((res) => {
            this.itemsCategoria = res.data;
          });
      }
      return Promise.resolve();
    },
    carregarFiltros() {
      Promise.all([
        this.buscarCategorias(null),
      ]).then(() => {
      });
    },
    exibirBotaoExibicaoMultiplasNds(item) {
      return item.qtdNds && item.qtdNds === 'MULTIPLAS' && item.listaMultiplasNds && item.listaMultiplasNds.length;
    },
    abrirModalExibicaoMultiplasNds(item) {
      this.$refs.ModalExibicaoMultiplasNds.open(item);
    },
    organizarColunasOrdenacao(params) {
      if (params.colunaOrdenacao === 'idAcao') {
        params.colunaOrdenacao = 'id_acao';
      }
      if (params.colunaOrdenacao === 'numNotaDebito') {
        params.colunaOrdenacao = 'num_nota_debito';
      }
      if (params.colunaOrdenacao === 'usuarioCriador') {
        params.colunaOrdenacao = 'usuario_criador';
      }
      if (params.colunaOrdenacao === 'nomeTipoUso') {
        params.colunaOrdenacao = 'nome_tipo_uso';
      }
      if (params.colunaOrdenacao === 'dataContabilizacao') {
        params.colunaOrdenacao = 'data_contabilizacao';
      }
      if (params.colunaOrdenacao === 'valorRecebimento') {
        params.colunaOrdenacao = 'valor_recebimento';
      }
      if (params.colunaOrdenacao === 'prazoPagamento') {
        params.colunaOrdenacao = 'prazo_pagamento';
      }
      if (params.colunaOrdenacao === 'descTipoBeneficio') {
        params.colunaOrdenacao = 'desc_tipo_beneficio';
      }
    },
  },
  mounted() {
    this.dialog = this.abrirModalPendencia;
    this.open();
  },
};
</script>
<style lang="scss">

.DashboardModalPendencias--background-color {
  background-color: #EEEEEE !important;
}

</style>
