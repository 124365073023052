<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="40%">
    <v-form ref="formJustificativa" v-model="valido"  v-on:submit.prevent lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">{{ title || $t('label.informe_justificativa') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="12">
                <v-text-field :label="`${$t('label.justificativa')} *`"
                              :rules="[rules.required]"
                              :disabled="bloquearJustificativa"
                              required v-model="justificativa"
                              v-on:keyup.enter="afirmativo()"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!isNovoValor" color="primary" text @click="bloquearJustificativa ? close() : cancelar()">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary" text v-if="!bloquearJustificativa"
                 @click="afirmativo()">{{ textoBotaoAfirmativo ? textoBotaoAfirmativo : $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalJustificativa',
  props: {
    title: '',
    textoBotaoAfirmativo: null,
    notaDebito: {
      type: Object,
      default: () => ({}),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valido: false,
      dialog: false,
      bloquearJustificativa: true,
      justificativa: '',
      rules: {
        required: (value) => (!!value && value.trim().length > 0) || this.$t('message.campo_obrigatorio'),
      },
      isNovoValor: false,
    };
  },
  methods: {
    open(isNovoValor) {
      this.isNovoValor = isNovoValor;
      this.bloquearJustificativa = this.somenteLeitura;
      this.justificativa = this.notaDebito.justificativa;
      this.dialog = true;
    },
    close() {
      if (!this.bloquearJustificativa) {
        this.$refs.formJustificativa.reset();
      }
      this.dialog = false;
    },
    afirmativo() {
      if (!!this.justificativa && this.justificativa.trim() === '') {
        this.justificativa = null;
      }
      if (!this.$refs.formJustificativa.validate()) {
        return;
      }
      this.notaDebito.justificativa = this.justificativa;
      this.$emit('ModalJustificativa__afirmativo');
      this.close();
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
