<template>
  <v-menu
    ref="menu"
    :close-on-content-click="false"
    v-model="pickerData"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="somenteLeitura"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-model="valorApresentavel"
        :label="inputLabel"
        :id="inputId"
        :disabled="somenteLeitura"
        :required="campoObrigatorio"
        :rules="[rules.requiredDate]"
        clearable
        @click:clear="limpaData"
        prepend-icon="event"
        readonly
        @focus="emiteValorAntigo(valorApresentavel)"
      ></v-text-field>
    </template>

    <v-date-picker
      ref="picker"
      locale="pt-br"
      color="primary"
      v-model="valorInterno"
      :no-title="isTipoSelecaoYear"
      :reactive="isTipoSelecaoYear"
      :disabled="somenteLeitura"
      :required="campoObrigatorio"
      :type="tipoSelecao"
      :allowed-dates="datasPermitidas"
      :min="dtaMinima"
      :max="dtaMaxima"
      @change="change"
      @input="dataAlterada()">
    </v-date-picker>
  </v-menu>
</template>
<script>
import moment from 'moment';
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoData',
  props: {
    value: {
      type: [String],
    },
    isDataInicio: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
    isDataFim: {
      type: Boolean,
      default: false,
    },
    isVigenciaTrimestral: {
      type: Boolean,
      default: false,
    },
    aplicarRestricaoDataAtual: {
      type: Boolean,
      default: false,
    },
    vlrPadrao: {
      type: [String],
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
        setTimeout(() => this.alteraValorApresentavel());
      },
    },
    menu(val) {
      if (val) {
        if (this.$refs.picker !== undefined) {
          this.$refs.picker.activePicker = 'YEAR';
        }
      }
    },
    vlrPadrao: {
      immediate: true,
      handler(dataPadrao) {
        if (dataPadrao) {
          this.valorInterno = dataPadrao;
          setTimeout(() => this.alteraValorApresentavel());
          this.dataAlterada();
        }
      },
    },
  },
  computed: {
    formatoData() {
      const { desAtributos } = this.metadados;
      if (!desAtributos || !desAtributos.formatoData) return 'DD-MM-YYYY';
      return desAtributos.formatoData;
    },
    dtaMinima() {
      const { desAtributos } = this.metadados;
      if (!desAtributos || !desAtributos.dtaMinima) return null;
      switch (desAtributos.dtaMinima) {
        case 'atual': {
          return moment().format('YYYY-MM-DD');
        }
        case 'amanha': {
          return moment().add(1, 'days').format('YYYY-MM-DD');
        }
        case null: {
          return null;
        }
        default:
          return desAtributos.dtaMinima;
      }
    },
    dtaMaxima() {
      const { desAtributos } = this.metadados;
      if (this.aplicarRestricaoDataAtual) {
        return moment().subtract(1, 'days').format('YYYY-MM-DD');
      }
      if (!desAtributos || !desAtributos.dtaMaxima) return null;
      switch (desAtributos.dtaMaxima) {
        case 'atual': {
          return moment().format('YYYY-MM-DD');
        }
        case 'amanha': {
          return moment().add(1, 'days').format('YYYY-MM-DD');
        }
        case null: {
          return null;
        }
        default:
          return desAtributos.dtaMaxima;
      }
    },
    isTipoSelecaoYear() {
      return this.tipoSelecao === 'year';
    },
  },
  data() {
    return {
      valorInterno: null,
      valorApresentavel: null,
      pickerData: null,
      rules: {
        requiredDate: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno;
          return !!(val) || msg;
        },
      },
    };
  },
  methods: {
    alteraValorApresentavel() {
      if (!this.valorInterno) {
        this.valorApresentavel = null;
      } else if (this.tipoSelecao === 'date') {
        this.valorApresentavel = moment(this.valorInterno, 'YYYY-MM-DD').format(this.formatoData);
      } else if (this.isTipoSelecaoYear) {
        this.valorApresentavel = moment(this.valorInterno, 'YYYY-MM').format('YYYY');
        this.$refs.menu.save(this.valorInterno);
        if (this.$refs.picker !== undefined) {
          this.$refs.picker.activePicker = 'YEAR';
        }
        this.pickerData = false;
      } else {
        if (this.isDataInicio) {
          this.valorApresentavel = moment(this.valorInterno, 'YYYY-MM').startOf('month').format(this.formatoData);
        }
        if (this.isDataFim) {
          this.valorApresentavel = moment(this.valorInterno, 'YYYY-MM').endOf('month').format(this.formatoData);
        }
      }
    },
    change() {
      this.$emit('DataAlterada', this.valorInterno);
    },
    limpaData() {
      this.valorInterno = null;
      this.emiteValorAlterado(null);
      setTimeout(() => this.alteraValorApresentavel());
    },
    dataAlterada() {
      this.emiteValorAlterado(this.valorInterno);
      this.pickerData = false;
      setTimeout(() => this.alteraValorApresentavel());
    },
    datasPermitidas(val) {
      if (this.isVigenciaTrimestral) {
        const valor = parseInt(val.split('-')[1], 10);
        if (this.isDataInicio) {
          return valor === 1 || valor === 4 || valor === 7 || valor === 10;
        }
        return valor === 3 || valor === 6 || valor === 9 || valor === 12;
      }
      return true;
    },
    emiteValorAntigo(valorApresentavel) {
      this.$emit('focus', valorApresentavel);
    },
  },
  mounted() {
    const { desAtributos } = this.metadados;
    if (this.isTipoSelecaoYear) {
      this.valorInterno = desAtributos.valorInterno;
      this.emiteValorAlterado(this.valorInterno);
      setTimeout(() => this.alteraValorApresentavel());
    }
  },
};
</script>
