<template>
  <div class="PlanejamentoContratoFormAcoes" v-if="objetoPassoGeral">
    <planejamento-contrato-form-acoes-controle
      ref="controleAcoes"
      :alterarFinalizado="alterarFinalizado"
      :copia="copia"
      :edicao="edicao"
      :novo="novo"
      :configuracao="configuracao"
      :objeto-passo-geral-contrato="objetoPassoGeral"
      :contrato-edicao="contratoEdicao"
      :pode-solicitar-aprovacao-contrato="podeSolicitarAprovacaoContrato"
      :tem-fluxo-contrato="temFluxoContrato"
      :novo-contrato="novoContrato"
      :somente-leitura="somenteLeitura"
      :get-objeto-outro-passo="getObjetoOutroPasso"
      @PlanejamentoContratoFormAcoesControle__carregarContrato="carregarContrato"
      @PlanejamentoContratoFormAcoesControle__atualizarStatusContrato="atualizarStatusContrato"
      @PlanejamentoContratoFormAcoesControle__indSemVerba="setIndSemVerba"
      @PlanejamentoContratoFormAcoesControle__setValorContrato="setValorContrato"
      @PlanejamentoContratoFormAcoesControle__MostrarMsgValidacaoDataAcao="mostrarMsgValidacaoDataAcao"/>

    <modal-categorias-duplicadas
      ref="modalCategoriasDuplicadas"/>

    <modal-acoes-duplicadas
      ref="modalAcoesDuplicadas"/>
  </div>
</template>
<script>
import PlanejamentoContratoFormAcoesControle from './PlanejamentoContratoFormAcoesControle';
import ModalCategoriasDuplicadas from './ModalCategoriasDuplicadas';
import ModalAcoesDuplicadas from './ModalAcoesDuplicadas';

export default {
  props: {
    alterarFinalizado: Boolean,
    copia: Boolean,
    edicao: Boolean,
    novo: Boolean,
    novoContrato: Boolean,
    podeSolicitarAprovacaoContrato: Boolean,
    temFluxoContrato: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  components: {
    PlanejamentoContratoFormAcoesControle,
    ModalCategoriasDuplicadas,
    ModalAcoesDuplicadas,
  },
  data: () => ({ objetoPassoGeral: null, indSemVerba: false, msgValidacaoAcao: false }),
  methods: {
    carregarContrato(idContrato) {
      this.$emit('PlanejamentoContratoFormAcoes__carregarContrato', idContrato);
    },
    acoesCarregadas() {
      if (this.$refs.controleAcoes) {
        this.$refs.controleAcoes.atualizaListaCategoriasAdicionadas();
      }
    },
    setValorContrato(value) {
      this.$emit('PlanejamentoContratoFormAcoes__setValorContrato', value);
    },
    setIndSemVerba(value) {
      this.indSemVerba = value;
      this.$emit('PlanejamentoContratoFormAcoesControle__indSemVerba', value);
    },
    getObjetoFormatado() {
      return this.$refs.controleAcoes.acoesParaEnvio();
    },
    validacaoAssincrona() {
      return Promise.resolve(this.$refs.controleAcoes.validaDuplicidadeContratoAoSalvar());
    },
    valida() {
      let validado = this.$refs.controleAcoes.validaCamposComuns();
      if (!validado) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
        return false;
      }

      this.$refs.controleAcoes.organizarAcoesPreenchidas();
      validado = this.$refs.controleAcoes.qtdeAcoes() > 0;
      if (!validado) {
        this.$toast(this.$t('errors.contrato.sem_acao_cadastrada'));
        return false;
      }

      if (!this.indSemVerba) {
        validado = this.$refs.controleAcoes.validaAcoes();
        if (!validado && this.msgValidacaoAcao) {
          this.$toast(this.$t('errors.contrato.acoes_fora_vigencia'));
        }
        if (!validado) {
          return false;
        }
      }

      if (!this.indSemVerba) {
        if (this.msgValidacaoAcao) {
          this.$toast(this.$t('errors.contrato.acoes_fora_vigencia'));
          return false;
        }
      }

      const objValidacao = this.$refs.controleAcoes.validaAcoesDuplicadasCategoriasNaoAgrupadas();
      if (!objValidacao.validado) {
        this.$refs.modalCategoriasDuplicadas.abrirModal(objValidacao);
        return false;
      }

      const objValidacaoAcaoIgual = this.$refs.controleAcoes.validaAcoesDuplicadasMesmaCategoria();
      if (!objValidacaoAcaoIgual.validado) {
        this.$refs.modalAcoesDuplicadas.abrirModal(objValidacaoAcaoIgual);
        return false;
      }

      if (!validado) {
        return false;
      }

      this.$refs.controleAcoes.somarValorContrato();
      return true;
    },
    abrePasso() {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
    },
    setarExibeDica(valor = false) {
      this.$refs.controleAcoes.setarExibeDica(valor);
    },
    teveOrigemAlterada() {
      if (this.objetoPassoGeral != null) {
        const passoGeral = this.getObjetoOutroPasso('geral');
        const fornecedorNew = passoGeral.fornecedor,
          grupoFornecedorNew = passoGeral.grupoFornecedor,
          fornecedorOld = this.objetoPassoGeral.fornecedor,
          grupoFornecedorOld = this.objetoPassoGeral.grupoFornecedor;
        return (fornecedorNew && !fornecedorOld) || (grupoFornecedorNew && !grupoFornecedorOld)
          || (fornecedorNew && fornecedorOld && fornecedorNew.id !== fornecedorOld.id)
          || (grupoFornecedorNew && grupoFornecedorOld && grupoFornecedorNew.id !== grupoFornecedorOld.id);
      }
      return false;
    },
    dataVigenciaContratoAlterada() {
      if (this.objetoPassoGeral != null) {
        const passoGeral = this.getObjetoOutroPasso('geral');
        const dataInicioNew = this.moment(passoGeral.dtaVigenciaInicio);
        const dataFimNew = this.moment(passoGeral.dtaVigenciaFim);
        const dataInicioOld = this.moment(this.objetoPassoGeral.dtaVigenciaInicio);
        const dataFimOld = this.moment(this.objetoPassoGeral.dtaVigenciaFim);
        return !dataInicioNew.isSame(dataInicioOld) || !dataFimNew.isSame(dataFimOld);
      }
      return false;
    },
    getNovaOrigem() {
      const passoGeral = this.getObjetoOutroPasso('geral');
      return passoGeral.grupoFornecedor || passoGeral.fornecedor;
    },
    removerCategoriasPorOrigemAlterada() {
      this.$refs.controleAcoes.removerCategoriasPorOrigemAlterada();
    },
    atualizarStatusContrato(statusContrato) {
      this.$emit('PlanejamentoContratoFormAcaoes__atualizarStatusContrato', statusContrato);
    },
    atualizarDatasAcoes(indDatasAlteradas) {
      this.$refs.controleAcoes.atualizarDatasAcoes(indDatasAlteradas);
    },
    validaVigenciasAcoes() {
      if (this.$refs.controleAcoes) {
        this.$refs.controleAcoes.validaVigenciasAcoes();
      }
    },
    getObjetoPasso() {
      $logger.log('getObjetoPasso', 'Não implementado');
    },
    mostrarMsgValidacaoDataAcao(mostrar) {
      this.msgValidacaoAcao = mostrar;
    },
  },
  mounted() {
    if (!this.novo) {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
    }
  },
};
</script>
