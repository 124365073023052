import { render, staticRenderFns } from "./PlanejamentoContratoFormCampos.vue?vue&type=template&id=3890829d"
import script from "./PlanejamentoContratoFormCampos.vue?vue&type=script&lang=js"
export * from "./PlanejamentoContratoFormCampos.vue?vue&type=script&lang=js"
import style0 from "./PlanejamentoContratoFormCampos.vue?vue&type=style&index=0&id=3890829d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports