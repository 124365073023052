<template lang="html">
  <v-dialog v-model="showModal" :max-width="isContrato? '1200px' : '650px'">
    <v-card>
      <v-card-title class="d-flex justify-center  bg-surface-variant">
        <span class="headline font-weight-black">
          {{ tituloModal }}
        </span>
      </v-card-title>
      <v-card-text v-if="!isContrato">
        <span class="text-h6 font-weight-regular">
          {{ $t('message.deseja_realmente_solicitar_acao_selecionada', {tituloModal: tituloModal}) }}
        </span>
      </v-card-text>
      <div class="px-5 py-5">
        <div v-if="isContrato && aprovacao">
          <v-card-text>
            <span class="font-weight-regular text-h6" >
              {{ $t('message.deseja_realmente_aprovar_contratos_selecionados', 1) }}
            </span>
          </v-card-text>
          <v-card-text class="pb-0" v-if="exibirMsgSubstituicaoContrato">
            <span class=" text-h6 red--text" >
              {{ $t('title.atencao_alerta', 1) }}
            </span>
          </v-card-text>
          <v-card-text class="pt-0" style="text-align: justify" v-if="exibirMsgSubstituicaoContrato">
            <span class="font-weight-regular text-h6 text-high-emphasis" >
              {{ $t('message.deseja_realmente_aprovar_contratos_selecionados_texto', 1) }}
            </span>
          </v-card-text>
        </div>
        <div v-if="isContrato && analise">
          <v-card-text>
            <span class="font-weight-regular text-h6" >
              {{ $t('message.deseja_realmente_solicitar_analise_contratos_selecionados', 1) }}
            </span>
          </v-card-text>
        </div>

        <v-form ref="form" lazy-validation autocomplete="off">
          <v-container class="grey lighten-5">
            <v-row>
              <v-col cols="5">
                <v-text-field v-if="aprovacao && isContrato"
                  v-model="prazoPagamento"
                  :label="`${$tc('label.prazo_pagamento_dias', 1)}`"
                  type="Number"
                  class="plan_acao_prazo_vencimento pt-0"
                  min="1"
                  :hint="$tc('label.prazo_vencimento_padrao', 1)"
                  :persistent-hint="true"
                  required
                />
                <!-- <v-menu
                v-if="aprovacao && isContrato"
                ref="data_prazo_pagamento"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    id="data_prazo_pagamento"
                    class="CalendarioForm-data__text"
                    readonly
                    clearable
                    v-model="dataFormatada"
                    :label="`${$t('label.prazo_pagamento')}`"
                    prepend-icon="event">
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  scrollable
                  @input="setaDataFormatada()"
                  v-model="dataPreenchida">
                </v-date-picker>
              </v-menu> -->
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-if="aprovacao"
                  name="justificativa"
                  :label="`${$tc('label.justificativa', 1)}`"
                  v-model="justificativa"
                  auto-grow
                  autofocus
                  rows="1"
                  maxlength="150">
                </v-textarea>
                <v-textarea
                  v-if="!aprovacao"
                  name="justificativa"
                  :label="`${$tc('label.justificativa', 1)} *`"
                  v-model="justificativa"
                  :rules="[rules.required]"
                  auto-grow
                  autofocus
                  rows="1"
                  maxlength="150">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="close">
            {{ $t('label.cancelar') }}
          </v-btn>
          <v-btn v-if="aprovacao"
            color="primary darken-1"
            @click.native="aprovar">
            {{ $t('label.aprovar') }}
          </v-btn>
          <v-btn v-if="reprovacao"
            color="primary darken-1"
            @click.native="reprovar">
            {{ $t('label.reprovar') }}
          </v-btn>
          <v-btn v-if="analise"
            color="primary darken-1"
            @click.native="executarAnalise">
            {{ $t('label.solicitar_analise') }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      showModal: false,
      colorPalette: 'amber lighten-1', // Amarelo
      dataFormatada: null,
      prazoPagamento: null,
      dataPreenchida: new Date().toJSON().substr(0, 10),
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorMinimo: (value, min) => value >= min || this.$t('errors.valor.minimo', { min }),
      },
      justificativa: '',
      exibirMsgSubstituicaoContrato: false,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    aprovacao() {
      return this.tituloModal === this.$tc('label.aprovar', 1);
    },
    reprovacao() {
      return this.tituloModal === this.$tc('label.reprovar', 1);
    },
    analise() {
      return this.tituloModal === this.$tc('label.solicitar_analise', 1);
    },
  },
  props: {
    tituloModal: String,
    isContrato: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open(contratosSelecionados = null) {
      if (contratosSelecionados && contratosSelecionados.length) {
        this.contratosSelecionados = [...contratosSelecionados];
        const index = 0;
        this.buscarContratosRenovacao(index);
      } else {
        this.showModal = true;
      }
    },
    close() {
      this.resetCampos();
      this.showModal = false;
    },
    aprovar() {
      if (!this.$refs.form.validate()) return;
      let param = {};
      if (this.isContrato) {
        param = {
          justificativa: this.justificativa,
          prazoPagamento: this.prazoPagamento,
        };
      } else {
        param = {
          justificativa: this.justificativa,
        };
      }
      this.$emit('aprovar', param);
      this.close();
    },
    reprovar() {
      if (!this.$refs.form.validate()) return;
      const param = {
        justificativa: this.justificativa,
      };
      this.$emit('reprovar', param);
      this.close();
    },
    executarAnalise() {
      if (!this.$refs.form.validate()) return;
      const param = {
        justificativa: this.justificativa,
      };
      this.$emit('executar_analise', param);
      this.close();
    },
    resetCampos() {
      this.prazoPagamento = null;
      this.justificativa = '';
      this.exibirMsgSubstituicaoContrato = false;
    },
    buscarContratosRenovacao(index) {
      if (index >= this.contratosSelecionados.length) {
        this.showModal = true;
        return;
      }
      const idContrato = this.contratosSelecionados[index].id;
      this.workflowContratoResource.buscarContratosRenovacao({ idContrato })
        .then((res) => {
          if (res.data.length) {
            this.exibirMsgSubstituicaoContrato = true;
            this.showModal = true;
          } else {
            this.buscarContratosRenovacao(index + 1);
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
<style>
  .plan_acao_prazo_vencimento .v-messages__message {
    color: #7a7978;
  }
</style>
