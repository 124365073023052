<template lang="html">
  <div style="height: 100%">
    <v-card class="card-size" height="100%">
      <v-card-title>
        <h3 class="headline mb-0" v-text="title"></h3>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="searchQuery"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        class="ml-3"
        :headers="headersDinamico"
        :items="divisoes"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="justify-center px-0" style="width: 1%; text-align: center;" v-if="!somenteLeitura">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click="onEditarDivisao(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.editar') }}</span>
                </v-tooltip>
              </td>
              <td>{{ item.idExterno }}</td>
              <td>{{ item.nome }}</td>
              <td>{{ item.estruturaUsuario.nome }}</td>
              <td>{{ item.getNomesSeparadosPorVirgula('divisoesSuperiores', 'nome') }}</td>
              <td>{{ getDiretoria(item.desAtributos) ? $t('label.sim') : $t('label.nao') }}</td>
              <td v-for="entidade in Object.keys(metadadosDivisao.mapaEntidades)">
                {{ item.getNomesSeparadosPorVirgula(entidade, 'nomExtensao') }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Divisao from './Divisao';

export default {
  data() {
    return {
      divisaoResources: this.$api.divisao(this.$resource),
      divisoes: [],
      esperar: false,
      headers: [
        { text: this.$tc('label.cod_divisao', 1), width: '10%', value: 'id_externo' },
        { text: this.$tc('label.nome', 1), width: '20%', value: 'nome' },
        { text: this.$tc('label.estrutura_usuario', 1), value: 'nome_estrutura_usuario' },
        { text: this.$tc('label.divisao_superior', 1), value: 'split_part(divisoes_superiores, \'|\', 2)' },
        {
          text: this.$tc('label.diretoria', 1), value: 'is_diretoria', width: '10%', sortable: false,
        },
      ],
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      registros: [],
    };
  },
  props: {
    title: String,
    configCarteira: String,
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    filteredList() {
      return [];
    },
    ...generateComputed('Divisao', [
      'canEdit',
      'canGet',
      'canAccessPage',
    ]),
    ...mapGetters('metadados', [
      'getDivisaoMetadado',
    ]),
    metadadosDivisao() {
      if (this.getDivisaoMetadado && this.getDivisaoMetadado.mapaEntidades) {
        return this.getDivisaoMetadado;
      }
      return undefined;
    },
    headersDinamico() {
      const headers = Object.assign([], this.headers);

      if (!this.metadadosDivisao) return headers;

      Object.keys(this.metadadosDivisao.mapaEntidades).forEach((entidade) => {
        const mapeamentoEntidade = this.metadadosDivisao.mapaEntidades[entidade];
        headers.push({
          text: mapeamentoEntidade.entidadeEstrangeira,
          value: mapeamentoEntidade.label,
          sortable: false,
        });
      });

      if (!this.somenteLeitura) {
        headers.unshift({
          text: '', value: 'acoes', sortable: false, width: '1%', align: 'center',
        });
      }

      return headers;
    },
    somenteLeitura() {
      return !this.canEdit && this.canGet;
    },
  },
  methods: {
    buscar() {
      const params = {
        filtro: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.divisaoResources.listar(params).then((response) => {
        this.registros = response.data.resposta;
        this.montarObjetoDivisoes();
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    buscarDivisoesPorEstruturaDeUsuario(divisaoSelecionada) {
      return (filtro) => {
        const params = { ...filtro, idEstruturaUsuario: divisaoSelecionada.estruturaUsuario.id };
        return this.divisaoResources.pesquisasrDivisoesSuperiores(params);
      };
    },
    buscarEstruturasDeUsuario(nomeEstruturaUsuario = null) {
      const filtro = { ...nomeEstruturaUsuario, size: 50, page: 0 };
      return this.estruturaUsuarioResources.listar(filtro);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    getDiretoria(divisao) {
      divisao = divisao && divisao.value
        ? JSON.parse(divisao.value) : {};
      return divisao ? divisao.diretoria : false;
    },
    montarColunasDinamicas() {
      const objeto = [];
      Object.keys(this.metadadosDivisao.mapaEntidades).forEach((entidade) => {
        objeto.push(this.metadadosDivisao.mapaEntidades[entidade].label);
      });
      return objeto;
    },
    montarObjetoDivisoes() {
      this.divisoes = this.registros.map((data) => {
        const divisao = new Divisao();
        divisao.construirAPartirDaConsultaCompleta(data);
        divisao.montarEstruturaDinamica(data, this.metadadosDivisao);
        return divisao;
      });
    },
    onEditaDivisoesSuperiores(divisao) {
      this.divisaoResources.atualizarDivisoesSuperiores({
        id: divisao.id,
        idEstruturaUsuario: divisao.estruturaUsuario.id,
      }, divisao.montarArrayComIdsDasDivisoesSuperiores());
    },
    onEditarDivisao(divisao) {
      if (!divisao.desAtributos) {
        divisao.desAtributos = { diretoria: false };
      }
      this.$emit('PESQUISA_DIVISAO_EDITAR', divisao);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    setEstruturaUsuario(hit, row, nome) {
      this.objEstruturaUsuario.id = row.id;
      this.objEstruturaUsuario.nomEstruturaUsuario = nome;
      this.objEstruturaUsuario.estruturaUsuarioPaiId = hit.map((el) => el.id);
      this.updateItemDependency(row, 'estruturaUsuarioPai', this.objEstruturaUsuario);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
